import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Welcome from '../components/welcome'
import Layout from '../components/layout'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [contact] = get(this, 'props.data.allContentfulKontakt.edges')

    return (
      <Layout location={this.props.location} showHeader={false} showFooter={false}>
        <Helmet title={siteTitle}>
          <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
        </Helmet>
        <Welcome data={contact.node} />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query RootQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulKontakt(
      filter: { contentful_id: { eq: "1rCvAfQs6L8n0buVr2XP27" } }
    ) {
      edges {
          node {
            name
            title
            email
          }
        }
    }
  }
`
